import React, { Component } from "react";
import { Row, Col, Container } from 'reactstrap';
import './style.css';
import { LandingPageHeader, ExamplesNavbar, Register, DemoFooter } from './../../../components/molecules/';
import Carousel from 'react-bootstrap/Carousel';

//img
import header_img_01 from '../../../assets/img/new/header-image-01.png';
import pak_chandra_1 from '../../../assets/img/new/pak-chandra-1.png';
import icon_type from '../../../assets/img/new/group-342.png';

class Homepage extends Component {
    state = {
        
    }

    render() {
        return (
            <>
                <ExamplesNavbar />

                <div className="section">
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='6' md='6' sm='12' xs='12' className='mt-5 mb-5'>
                                <div className="text-wrapper-7 mb-5">NIEC English Course</div>
                                <div>
                                    <p className="kelas-persiapan mb-5">Kelas Persiapan Bahasa Kuliah ke Luar Negeri</p>
                                    <p className="mulailah-perjalanan mb-5">Mulailah perjalanan kamu dalam menguasai Bahasa Inggris bersama kami sekarang juga!</p>
                                </div>
                                <Row>
                                    {/* <Col lg='5' md='5' sm='12' xs='12' className='mb-3'>
                                        <div className='button-header'>
                                            <a href="#trial"><div className="text-wrapper-3">Try Trial Class</div></a>
                                        </div>
                                    </Col> */}
                                    <Col lg='5' md='5' sm='12' xs='12'>
                                        <div className='button-header'>
                                            <a href="#placement"><div className="text-wrapper-3">Try Placement Test</div></a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <img src={header_img_01} className='img-fluid mt-5 mb-5' />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div >
                <Container>
    <Row className='justify-content-center'>
        <Col lg='12' md='12' sm='12' xs='12'>
            <h1 className="text-center judul-utama">Mengapa NIEC English Course?</h1>
            <h4 className="text-center judul-subutama mb-5">4 Alasan kenapa kursus bahasa di NIEC</h4>  
        </Col>
        {/* Tambahkan kelas khusus untuk mengatur background dan padding */}
        <div className="container">
            <div className="row center">
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="">
                        <h1 className='kelas-persiapan text-center'>Meningkatkan Skor IELTS/TOEFL/Duolingo</h1>
                    </div>
                    <p className="desk-1 text-justify">Kamu bisa belajar strategi-strategi jitu dalam mengerjakan test bahasa Inggris untuk mendapatkan nilai yang maksimal
                    </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="">
                        <h1 className='kelas-persiapan text-center'>Berpeluang Besar Mendapatkan Beasiswa</h1>
                    </div>
                    <p className="desk-1 text-justify">Menguasai Bahasa Inggris akan mempermudah kamu untuk memenuhi persyaratan dalam mencari beasiswa luar negeri</p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="">
                        <h1 className='kelas-persiapan text-center'>Menghemat Biaya Kuliah ke Luar Negeri</h1>
                    </div>
                    <p className="desk-1 text-justify">Banyak kampus luar negeri memberikan diskon biaya kuliah untuk mahasiswa dengan nilai tinggi dalam tes Bahasa Inggris
                    </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="">
                        <h1 className='kelas-persiapan text-center'>Meningkatkan Peluang Karier</h1>
                    </div>
                    <p className="desk-1 text-justify">Bahasa Inggris adalah salah satu keterampilan yang paling dicari di dunia kerja, baik di dalam maupun luar negeri sehingga sertifikat bahasa Inggris bisa dijadikan acuan kemampuan dalam peningkatan karier</p>
                </div>
            </div>
            
        </div>
       
        {/* <div className="carousel-container bg-white p-5 rounded shadow">
            <Carousel className="text-center mt-5">
                <Carousel.Item>
                    <p className="kelas-persiapan" style={{ color:'rgb(202, 0, 0)' }}>Peluang Beasiswa</p>
                    <p>Menguasai Bahasa Inggris akan mempermudah persyaratanmu dalam mencari beasiswa luar negeri</p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="kelas-persiapan" style={{ color:'rgb(202, 0, 0)' }}>Menghemat biaya kuliah ke luar negeri</p>
                    <p>Banyak kampus luar negeri memberikan diskon biaya kuliah untuk mahasiswa dengan nilai tinggi dalam tes Bahasa Inggris</p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="kelas-persiapan" style={{ color:'rgb(202, 0, 0)' }}>Meningkatkan Peluang Karier</p>
                    <p>Bahasa Inggris adalah salah satu keterampilan yang paling dicari di dunia kerja, baik di dalam maupun luar negeri sehingga sertifikat bahasa Inggris bisa dijadikan acuan kemampuan dalam peningkatan karier</p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="kelas-persiapan" style={{ color:'rgb(202, 0, 0)' }}>Meningkatkan skor IELTS/TOEFL/Duolingo</p>
                    <p>Pahami kiat-kiat dan buka peluang lebih luas untuk meraih band maksimal</p>
                </Carousel.Item>
            </Carousel>
        </div> */}
    </Row>
</Container>

                </div>

                <div id='program'>
                    <Container id="trial">
                        <Row className='justify-content-center mt-5 pt-5'>
                            <Col lg='12'>
                                <p className="judul-utama" style={{textAlign: 'center'}}>Program English Course Kami</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* <div className="section p-5" >
                    <div className="overlap-group-4">
                        <Row className='ml-3'>
                            <Col lg='3' md='6' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">Trial Class</h3>
                                <p className="desk-1 text-left">Mau tes Bahasa Inggris GRATIS?</p>
                                <div className='button-header mb-5'>
                                    <a href="#register"><div className="text-wrapper-3">Daftar Trial Class!</div></a>
                                </div>
                            </Col>
                            <Col lg='3' md='6' sm='12' xs='12'>
                                <div className="overlap-30">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Gratis!</div>
                                        <div className="text-wrapper-31 pb-3">3x Pertemuan</div>
                                        <div className="judul-basic text-left p-3 mt-5">Rp. 0</div>
                                        <Row>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold free">1 jam/pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div> */}

                <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='ml-3 pt-5 pb-5'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">General English Class</h3>
                                <p className="desk-1 text-left">
                                    Dirancang buat kamu yang mau meningkatkan kemampuan Bahasa Inggris dalam membaca, mendengarkan, berbicara, dan menulis, dengan fokus pada teori dasar
                                </p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5 ">Rp. 2.031.250</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.787.500</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi 1-on-1 dengan teacher</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-26">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 3.250.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 2.860.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi 1-on-1 dengan teacher</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 20 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-27">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 1.406.250</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.237.500</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-28">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.250.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.980.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 20 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='ml-3 pt-5 pb-5'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">IELTS/TOEFL Preparation</h3>
                                <p className="desk-1 text-left">
                                Berencana kuliah di luar negeri? Tingkatkan skill bahasa Inggris mu dan pelajari strategi jitu untuk menghadapi tes IELTS ataupun TOEFL yang merupakan persyaratan untuk mendaftar di universitas luar negeri bersama kami!
                                </p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.250.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.980.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi 1-on-1 dengan teacher</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-26">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 3.600.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 3.168.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi 1-on-1 dengan teacher</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 20 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-27">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 1.625.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.430.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-28">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.600.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 2.288.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 20 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='ml-3 pt-5 pb-5'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">Duolingo English Test Preparation</h3>
                                <p className="desk-1 text-left">
                                Lagi nyari tes kemampuan Bahasa Inggris yang lebih terjangkau untuk kuliah di luar negeri? Duolingo English test solusinya! Yuk, pelajari cara menguasai setiap jenis soal di kelas persiapan Duolingo bersama kami!
                                </p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.250.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.980.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi 1-on-1 dengan teacher</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-26">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">No Available</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.430.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row> */}
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-27">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 1.625.000</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.430.000</div> */}
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-28">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">No Availabe</div>
                                        {/* <div className="judul-basic2 text-left pl-3 mt-2 ">Rp. 1.430.000</div> */}
                                        {/* <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 10 jam</p>
                                            </Col>
                                        </Row> */}
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='ml-3 pt-5 pb-5'>
                            <Col lg='5' md='5' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">iPass New Zealand : General English Class</h3>
                                <p className="desk-1 text-left">
                                Mau belajar Bahasa Inggris dengan kurikulum internasional? Kami bekerja sama dengan iPass dari New Zealand untuk menyediakan materi belajar standar yang dilengkapi sertifikat, mulai dari level pemula hingga mahir!
                                </p>
                                <p className="judul-2"><i>Hanya dibuka di minggu ke-1 dan ke-3 setiap bulannya</i></p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 3 Months</div>
                                        <div className="text-wrapper-31 pb-3">36x Pertemuan</div>
                                        <div className="judul-basic3 text-left pl-3 mt-5 ">Rp. 9.163.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi 1-on-1 dengan teacher</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 36 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-27">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group 3 Months</div>
                                        <div className="text-wrapper-31 pb-3">36x Pertemuan</div>
                                        <div className="judul-basic3 text-left pl-3 mt-5 ">Rp. 4.500.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Sesi grup 3-5 orang</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Total program 36 jam</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-28">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Self Learning</div>
                                        <div className="text-wrapper-31 pb-3">12 Months</div>
                                        <div className="judul-basic3 text-left pl-3 mt-5 ">Rp. 2.932.500</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Hanya Tersedia Online</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='justify-content-center  pt-5 pb-5'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">Specific Purpose</h3>
                                <p className="desk-1 text-left">
                                    Specifically designed for various professional settings to meet the corporate needs of English communication.
                                </p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 10</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.031.250</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">10x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-26">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 20</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 3.250.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">20x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-27">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group 10</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 1.406.250</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Min 3 students,</p>
                                                <p className="text-left font-bold">Max 5 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">10x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div> */}

                <div className="section" id="teacher">
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='4' md='4' sm='12' xs='12' className='mb-5'>
                                <p className="kelas-persiapan mt-5">Meet our Teachers</p>
                                <div className="mulailah-perjalanan mt-5">Memperkenalkan, tenaga pengajar kami yang tentunya handal dan sudah berpengalaman dalam mengajar Bahasa Ingrris!</div>
                            </Col>
                            <Col lg='8' md='8' sm='12' xs='12'>
                                <LandingPageHeader/>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="section" id="placement">
                    <Container>
                        <div className="group-wrapper">
                            <Row className='justify-content-center align-content-center'>
                                <Col lg='7' md='7' sm='12' xs='12'>
                                    <div className="track-any-hashtag-s-2 mt-5 ml-5">Placement Test Partner</div>
                                    <p className="don-t-waste-time-on-3 mt-4 ml-5">Asah skill bahasa inggrismu</p>
                                </Col>
                                <Col lg='4' md='4' sm='12' xs='12'>
                                    <div className="take-your-placement">
                                        <a href='https://canada-english.com/en/leveltest-niec' target="_blank" className="text-wrapper-4 text-center mt-3">Mulai Sekarang</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <div className="section">
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <p className="kelas-persiapan mt-5">NIEC English Course is about mastering a timeless skill</p>
                                <div className="mulailah-perjalanan mt-5">Daftar English Course sekarang</div>
                            </Col>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="section">
                    <Container>
                        <Register />
                    </Container>
                </div>

                <DemoFooter />
            </>
        )
    }
}

export default Homepage;